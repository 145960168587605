import { useMutation } from '@apollo/client';
import * as Menu from '@radix-ui/react-dropdown-menu';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import Icon from 'atoms/Icon';
import FullPageTable from 'atoms/react-table/FullPageTable';
import useMinMaxValues from 'atoms/react-table/tableFilters/useMinMaxValues';
import Tooltip from 'atoms/Tooltip';
import { useT } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { useListColumns } from 'components/List/ListView/useListColumns';
import { listState } from 'components/List/state';
import useSettings from 'components/Settings/useSettings';
import ResetUrlParamsToolbarItem from 'components/Toolbar/ResetUrlParamsToolbarItem';
import {
  AggregateSortByInput,
  ApiError,
  BatteryNotificationType,
  BatterySohState,
  ColumnVisibility,
  DashboardLightMessageType,
  DeviceConnectionStatus,
  DtcEventType,
  EntityAssociation,
  GetEntityListDoc,
  ListColumnId,
  ListSortDirection,
  MilStatus,
  SaveListViewSettingsDoc,
  StateAggregationFilterInput,
  StateAggregatorSortField,
  TripStatisticType,
} from 'generated/graphql';
import { SelectOption } from 'types';
import { cx, entries, keys, kmToMi } from 'utils';
import { getError, useQ } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';
import {
  columnIdToSortFieldMap,
  entityAssociationColumnMap,
  sortFieldMapToVehicleColumnId,
  listMinMaxValueColumns,
  maxItemsPerPage,
  defaultListName,
  aggregationFiltersToListColumnId,
} from '../utils';
import { ConditionalWrapper } from 'atoms/ConditionalWrapper';

interface LocationState {
  defaultList?: boolean;
  associationState?: EntityAssociation;
}

const ListView = () => {
  const history = useHistory();
  const currentFleetId = useCurrentFleetId();
  const { state }: { state: LocationState } = useLocation();
  const { idleTimeAsPercentage, listViewSettings } = useSettings();
  const [listRecoilState, setListRecoilState] = useRecoilState(listState);
  const [pageOffset, setPageOffset] = useState(0);
  const [selectedApiFilters, setSelectedApiFilters] = useState<StateAggregationFilterInput>();
  const [formState, setFormState] = useState<ColumnVisibility>(
    listViewSettings.savedListViews[listRecoilState.activeListViewName]?.columnVisibility,
  );
  const [sortBy, setSortBy] = useState<AggregateSortByInput | undefined>({
    field: StateAggregatorSortField.DeviceStatus,
    direction: ListSortDirection.Asc,
  });

  const {
    formState: { isDirty },
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
  });

  const {
    data,
    loading,
    refetch: refetchEntityList,
  } = useQ(GetEntityListDoc, {
    variables: {
      fleetIds: selectedApiFilters?.fleetIds ?? currentFleetId,
      filters: listRecoilState.selectedApiFilters ?? {
        entityAssociation: listViewSettings.savedListViews[listViewSettings.activeListView].entityAssociation,
      },
      sortBy,
      limit: maxItemsPerPage,
      offset: pageOffset * maxItemsPerPage,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [saveList] = useMutation(SaveListViewSettingsDoc, {
    onCompleted: async () => {
      successToast(tSafe('components.ListPage.toasts.list-settings-saved', { defaultValue: 'List settings saved' }));
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
    fetchPolicy: 'network-only',
  });

  const urlItems = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const entities = useMemo(() => data?.data ?? [], [data]);
  const uniqueValues = useMemo(
    () => ({
      make: Array.from(new Set([...(listRecoilState.uniqueValues.make ?? []), ...(data?.meta?.vehicleMakes || [])])),
      model: Array.from(new Set([...(listRecoilState.uniqueValues.model ?? []), ...(data?.meta?.vehicleModels || [])])),
    }),
    [data],
  );
  const minMaxValues = useMinMaxValues<typeof entities[0], ListColumnId>(entities, listMinMaxValueColumns);

  const disabledColumns = entityAssociationColumnMap[
    listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
  ]?.disabledColumns.reduce((a, v) => ({ ...a, [v]: false }), {});

  const enforcedColumns = entityAssociationColumnMap[
    listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
  ]?.enforcedColumns.reduce((a, v) => ({ ...a, [v]: true }), {});

  const filteredColumns =
    selectedApiFilters &&
    entries(selectedApiFilters).reduce(
      (a, [key, value]) => ({ ...a, [aggregationFiltersToListColumnId({ key, value }) as ListColumnId]: true }),
      {},
    );

  const columnVisibility = {
    ...entries(
      listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnVisibility ??
        listViewSettings.defaultListView.columnVisibility,
    ).reduce((a, [key, value]) => ({ ...a, [key]: value }), {}),
    ...enforcedColumns,
    ...disabledColumns,
    ...filteredColumns,
  };

  const table = useReactTable({
    columns: useListColumns({ useTableFiltering: false }),
    data: entities.map((x) => ({
      licencePlate: x.vehicle?.licencePlate,
      make: x.vehicle?.make,
      model: x.vehicle?.model,
      driverName: x.driver?.name ?? '',
      fleets: x.device?.primaryFleetName ? [x.device?.primaryFleetName] : x.user?.fleets.map((x) => x.name),
      deviceStatus: x.device?.connectionStatus,
      activeDtcs: x.activeDtcs,
      batteryCharge: x.batteryStatus?.currentState?.lastVoltage,
      batteryStatus: x.vehicleStatus?.batteryStatus,
      batteryHealth: x.batteryStatus?.stateOfHealth?.state,
      ecoScore: x.driverStatistics?.ecoScore,
      dashboardLights: x.activeDashboardLights,
      drivingTime: x.driverStatistics?.totalTimeDriven ?? undefined,
      distanceDriven: x.driverStatistics?.totalDistanceDriven ?? undefined,
      fuelConsumption: x.driverStatistics?.fuelConsumption,
      fuelEfficiency: x.driverStatistics?.fuelEfficiency,
      idleTime: Math.round(
        idleTimeAsPercentage ? x.driverStatistics?.idleTimePct ?? 0 : x.driverStatistics?.idleTimeHrs ?? 0,
      ),
      impactCount: x.driverStatistics?.numImpacts,
      longIdlingEventCount: x.driverStatistics?.numLongIdlingEvents,
      milStatus: x.vehicleStatus?.mil,
      driverId: x.driver?.id,
      vehicleId: x.vehicle?.id,
    })),
    initialState: {
      pagination: {
        pageSize: maxItemsPerPage,
      },
    },
    state: {
      columnOrder: listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnOrder,
      columnVisibility,
    },
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const {
    tSafe,
    commonTranslations: {
      enums: { dtcEventTypeDescriptionMap, tripStatisticTypeDescriptionMap },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },
        listPage: { enforced_column_tfn, filtered_column_tfn },
      },
    },
  } = useT();

  const entityAssociationOptions: SelectOption<EntityAssociation>[] = [
    {
      label: tSafe('components.ListPage.assigned-options.drivers-with-vehicles', {
        defaultValue: 'Drivers with Vehicles',
      }),
      value: EntityAssociation.DriversAndVehicles,
    },
    {
      label: tSafe('components.ListPage.assigned-options.all-drivers', {
        defaultValue: 'All drivers',
      }),
      value: EntityAssociation.AllDrivers,
    },
    {
      label: tSafe('components.ListPage.assigned-options.all-vehicles', {
        defaultValue: 'All vehicles',
      }),
      value: EntityAssociation.AllVehicles,
    },
    {
      label: tSafe('components.ListPage.assigned-options.unassigned-drivers', {
        defaultValue: 'Unassigned drivers',
      }),
      value: EntityAssociation.UnassignedDrivers,
    },
    {
      label: tSafe('components.ListPage.assigned-options.unassigned-vehicles', {
        defaultValue: 'Unassigned vehicles',
      }),
      value: EntityAssociation.UnassignedVehicles,
    },
  ];

  const sortFieldTextMap: Record<ListColumnId, string> = {
    batteryHealth: battery_health_text,
    batteryCharge: battery_voltage_text,
    batteryStatus: batteryStatus_text,
    dashboardLights: activeDashboardLights_text,
    deviceStatus: device_connection_status_text,
    distanceDriven: tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
    dtcPending: dtcEventTypeDescriptionMap[DtcEventType.Pending],
    dtcPermanent: dtcEventTypeDescriptionMap[DtcEventType.Permanent],
    dtcStored: dtcEventTypeDescriptionMap[DtcEventType.Stored],
    drivingTime: tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
    driverName: driver_text,
    ecoScore: tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    fleets: fleet_text,
    fuelConsumption: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelEfficiency: tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
    idleTime: idleTimeAsPercentage
      ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
      : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    longIdlingEventCount: longIdling_text,
    impactCount: impact_events_text,
    licencePlate: licencePlate_text,
    make: make_text,
    model: model_text,
    milStatus: mil_status_text,
    tripCount: tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
  };

  useEffect(() => {
    const params = entries(Object.fromEntries(urlItems)).map(([key, value]) => ({
      key: key.toString() as ListColumnId,
      value: value.split(','),
    }));

    if (!params.length) return;

    const getMilStatus = (param: string[]) => {
      if (param.includes('true') && param.includes('false')) return undefined;
      if (param.includes('true')) return MilStatus.On;
      if (param.includes('false')) return MilStatus.Off;
      return undefined;
    };

    const filters = params.reduce((acc, { key, value }) => {
      switch (key) {
        case ListColumnId.LicencePlate:
          acc.licencePlates = [...(acc.licencePlates ?? []), ...value];
          break;
        case ListColumnId.Make:
          acc.vehicleMakes = [...(acc.vehicleMakes ?? []), ...value];
          break;
        case ListColumnId.Model:
          acc.vehicleModels = [...(acc.vehicleModels ?? []), ...value];
          break;
        case ListColumnId.DriverName:
          acc.driverNames = [...(acc.driverNames ?? []), ...value];
          break;
        case ListColumnId.Fleets:
          acc.fleetIds = [...(acc.fleetIds ?? []), ...value];
          break;
        case ListColumnId.DeviceStatus:
          acc.deviceConnectedStatusIncludes = [
            ...(acc.deviceConnectedStatusIncludes ?? []),
            ...(value as DeviceConnectionStatus[]),
          ];
          break;
        case ListColumnId.MilStatus:
          acc.milStatus = getMilStatus(value);
          break;
        case ListColumnId.DashboardLights:
          acc.dashboardLightsIncludes = [
            ...(acc.dashboardLightsIncludes ?? []),
            ...(value as DashboardLightMessageType[]),
          ];
          break;
        case ListColumnId.DtcPending:
          if (value.includes('true'))
            acc.dtcsIncludeType = Array.from(
              new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Pending]),
            ) as DtcEventType[];
          break;
        case ListColumnId.DtcStored:
          if (value.includes('true'))
            acc.dtcsIncludeType = Array.from(
              new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Stored]),
            ) as DtcEventType[];
          break;
        case ListColumnId.DtcPermanent:
          if (value.includes('true'))
            acc.dtcsIncludeType = Array.from(
              new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Permanent]),
            ) as DtcEventType[];
          break;

        case ListColumnId.BatteryHealth:
          acc.batterySohsIncludes = [...(acc.batterySohsIncludes ?? []), ...(value as BatterySohState[])];
          break;
        case ListColumnId.BatteryStatus:
          acc.batteryNotificationTypesIncludes = [
            ...(acc.batteryNotificationTypesIncludes ?? []),
            ...(value as BatteryNotificationType[]),
          ];
          break;

        case ListColumnId.DistanceDriven:
          const [min, max] = value;
          acc.distanceDrivenFloor = parseInt(min);
          acc.distanceDrivenCeiling = parseInt(max);
          break;
        case ListColumnId.LongIdlingEventCount:
          acc.driverHasLongIdlingEvents = true;
          break;
        case ListColumnId.ImpactCount:
          acc.driverHasImpacts = true;
          break;
        default:
          break;
      }

      return acc;
    }, {} as StateAggregationFilterInput);

    setSelectedApiFilters({
      ...filters,
      entityAssociation: state?.associationState
        ? state?.associationState
        : listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation,
    });
    setPageOffset(0);
  }, [urlItems, listRecoilState.activeListViewName, history.location.search]);

  // Update Recoil State
  useEffect(() => {
    setListRecoilState({
      activeListViewName: state?.defaultList
        ? defaultListName
        : listRecoilState.activeListViewName ?? keys(listViewSettings.defaultListView)[0],
      allListViews: keys(listRecoilState.allListViews).length
        ? listRecoilState.allListViews
        : listViewSettings.savedListViews,
      defaultListView: {
        ...listViewSettings.defaultListView,
        entityAssociation: state?.associationState ?? EntityAssociation.DriversAndVehicles,
      },
      selectedApiFilters,
      sortBy,
      uniqueValues,
      minMaxValues,
      onReset: () => {
        table.setColumnFilters([]);
      },
      tableWidth: table.getTotalSize(),
    });
  }, [listRecoilState.activeListViewName, selectedApiFilters, sortBy, uniqueValues]);

  const handleColumnOrder = (order: ListColumnId[]) => {
    const newListViewState = {
      ...listRecoilState.allListViews,
      [listRecoilState.activeListViewName]: {
        ...listRecoilState.allListViews[listRecoilState.activeListViewName],
        columnOrder: order,
      },
    };

    setListRecoilState({
      ...listRecoilState,
      allListViews: newListViewState,
    });

    saveList({
      variables: {
        activeListView: listRecoilState.activeListViewName,
        listViews: newListViewState,
      },
    });
  };

  const { getProps } = useDropdownSelect(entityAssociationOptions, {
    onSelect: ({ value }) => {
      history.location.state = {};

      const newListState = {
        ...listViewSettings.savedListViews,
        [listRecoilState.activeListViewName]: {
          ...listViewSettings.savedListViews[listRecoilState.activeListViewName],
          entityAssociation: value,
        },
      };

      setListRecoilState({
        ...listRecoilState,
        allListViews: newListState,
      });
      setSelectedApiFilters({ ...selectedApiFilters, entityAssociation: value });
      saveList({
        variables: {
          activeListView: listRecoilState.activeListViewName,
          listViews: newListState,
        },
      });
    },
  });

  const selectedDropdown = entityAssociationOptions.find(({ value }) => {
    if (state?.associationState) return value === state?.associationState;

    return (
      value === listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation ??
      listViewSettings.savedListViews[listViewSettings.activeListView]?.entityAssociation ??
      EntityAssociation.DriversAndVehicles
    );
  });

  return (
    <div className={cx('flex flex-col pt-0.5')}>
      <div className="py-1 w-full items-center flex justify-end border-b-px border-gray-300">
        <ResetUrlParamsToolbarItem
          table={table}
          resetFilters={() => {
            setSortBy(undefined);
            setSelectedApiFilters(undefined);
          }}
        />

        <div className="flex space-x-1 ml-1">
          <DropdownSelect
            innerWrapperClassName="w-[200px]"
            {...getProps()}
            selectedItem={entityAssociationOptions.find(({ value }) => value === selectedDropdown?.value) ?? null}
            className="z-600"
          />
        </div>
        <Menu.Root
          onOpenChange={(open) => {
            if (!open && isDirty) {
              reset(formState);
              saveList({
                variables: {
                  activeListView: listRecoilState.activeListViewName,
                  listViews: listRecoilState.allListViews,
                },
              });
            }
          }}
        >
          <Menu.Trigger className="">
            <div className="">
              <Tooltip text="Configure columns">
                <Icon className="ui-button-dark ml-1" name="columns" />
              </Tooltip>
            </div>
          </Menu.Trigger>

          <Menu.Content
            sideOffset={7}
            align="end"
            className="relative min-w-20 bg-white h-[500px] rounded-4 shadow-card text-sm overflow-y-scroll"
          >
            <h3 className="sticky p-1 text-md border-b-px border-gray-100">
              {tSafe('components.ListPage.column-options', { defaultValue: 'Column options' })}
            </h3>

            {table
              .getAllLeafColumns()
              .filter(
                ({ id }) =>
                  !entityAssociationColumnMap[
                    listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
                  ]?.disabledColumns.includes(id as ListColumnId),
              )
              .map(({ id }) => {
                const filtered =
                  selectedApiFilters &&
                  entries(selectedApiFilters)
                    .filter(([key, value]) => key !== 'entityAssociation')
                    .some(([key, value]) => aggregationFiltersToListColumnId({ key, value }) === id);

                const enforced = entityAssociationColumnMap[
                  listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
                ]?.enforcedColumns?.includes(id as ListColumnId);

                const visible =
                  listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnVisibility?.[
                    id as ListColumnId
                  ];

                const tooltipText = () => {
                  if (enforced) {
                    return enforced_column_tfn(
                      sortFieldTextMap[id as ListColumnId],
                      entityAssociationOptions.find(
                        (option) =>
                          option.value ===
                          listRecoilState.allListViews[listRecoilState.activeListViewName].entityAssociation,
                      )?.label ?? '',
                    );
                  }
                  if (filtered) {
                    return filtered_column_tfn(sortFieldTextMap[id as ListColumnId]);
                  }
                };

                return (
                  <label
                    key={id}
                    htmlFor={`${id}`}
                    className={cx(
                      'py-0.5 flex w-full items-center hover:bg-gray-100 cursor-pointer',
                      entityAssociationColumnMap[
                        listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
                      ]?.enforcedColumns?.includes(id as ListColumnId) && 'text-gray-300 cursor-not-allowed',
                    )}
                  >
                    <ConditionalWrapper
                      condition={enforced || !!filtered}
                      wrapper={({ children }: { children: JSX.Element }) => (
                        <Tooltip text={tooltipText()}>{children}</Tooltip>
                      )}
                    >
                      <>
                        <input
                          id={id}
                          onChange={(e) => {
                            setValue(id as ListColumnId, e.target.checked, {
                              shouldDirty: e.target.checked !== getValues()[id as ListColumnId],
                            });
                            setFormState({ ...formState, [id]: e.target.checked });
                            setListRecoilState({
                              ...listRecoilState,
                              allListViews: {
                                ...listRecoilState.allListViews,
                                [listRecoilState.activeListViewName]: {
                                  ...listRecoilState.allListViews[listRecoilState.activeListViewName],
                                  columnVisibility: {
                                    ...listRecoilState.allListViews[listRecoilState.activeListViewName]
                                      ?.columnVisibility,
                                    [id as ListColumnId]: e.target.checked,
                                  },
                                },
                              },
                            });
                          }}
                          type="checkbox"
                          className="m-1"
                          disabled={enforced || filtered}
                          checked={filtered || visible || enforced}
                        />
                        {sortFieldTextMap[id as ListColumnId]}
                      </>
                    </ConditionalWrapper>
                  </label>
                );
              })}

            <Button
              onClick={() => {
                setListRecoilState({
                  ...listRecoilState,
                  allListViews: {
                    ...listRecoilState.allListViews,
                    [listRecoilState.activeListViewName!]: {
                      ...listRecoilState.allListViews[listRecoilState.activeListViewName!],
                      columnOrder: listRecoilState.defaultListView.columnOrder,
                      columnVisibility: listRecoilState.defaultListView.columnVisibility,
                      entityAssociation: listRecoilState.defaultListView.entityAssociation,
                    },
                  },
                });
              }}
              className="sticky p-1 border-b-px border-gray-100 hover:underline"
            >
              {tSafe('components.ListPage.restore-defaults', { defaultValue: 'Restore defaults' })}
            </Button>
          </Menu.Content>
        </Menu.Root>
      </div>

      <FullPageTable
        table={table}
        totalCount={data?.count}
        currentPage={pageOffset}
        onPageChange={(selectedPage: number) => {
          setPageOffset(selectedPage);
        }}
        toggleSortBy={(id, direction) =>
          id &&
          setSortBy({
            field: columnIdToSortFieldMap(id as ListColumnId, idleTimeAsPercentage),
            direction: direction ? direction : undefined,
          })
        }
        sortedColumn={
          sortBy
            ? {
                field: sortFieldMapToVehicleColumnId(sortBy.field),
                direction: sortBy.direction ? sortBy.direction : undefined,
              }
            : null
        }
        setColumnOrder={handleColumnOrder}
        columnOrder={
          new Set(listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnOrder) ??
          listViewSettings.defaultListView.columnOrder
        }
        loading={loading}
        refetch={() => refetchEntityList()}
      />
    </div>
  );
};

export default ListView;
